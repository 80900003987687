import React, { Fragment } from 'react';
import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid';
import { StaticImage } from 'gatsby-plugin-image';

const tiers = [
  {
    name: 'Platinum',
    id: 'tier-platinum',
    href: '#',
    priceMonthly: '$1000+',
    description: 'Full Pull',
    mostPopular: true,
  },
  {
    name: 'Gold',
    id: 'tier-gold',
    href: '#',
    priceMonthly: '$1000',
    description: '350 FT',
    mostPopular: false,
  },
  {
    name: 'Silver',
    id: 'tier-silver',
    href: '#',
    priceMonthly: '$500',
    description: '300FT',
    mostPopular: false,
  },
  {
    name: 'Bronze',
    id: 'tier-bronze',
    href: '#',
    priceMonthly: '$300',
    description: '250FT',
    mostPopular: false,
  },
  {
    name: 'Exhibitor',
    id: 'tier-exhibitor',
    href: '#',
    priceMonthly: '$200',
    description: '200FT',
    mostPopular: false,
  },
];
const sections = [
  {
    name: 'Features',
    features: [
      {
        name: 'Custom promotion options',
        tiers: {
          Platinum: true,
          Gold: false,
          Silver: false,
          Bronze: false,
          Exhibitor: false,
        },
      },
      {
        name: 'Company name on packer',
        tiers: {
          Platinum: true,
          Gold: true,
          Silver: false,
          Bronze: false,
          Exhibitor: false,
        },
      },
      {
        name: 'Advertising on flyers',
        tiers: {
          Platinum: true,
          Gold: true,
          Silver: false,
          Bronze: false,
          Exhibitor: false,
        },
      },
      {
        name: 'Link on website',
        tiers: {
          Platinum: true,
          Gold: true,
          Silver: true,
          Bronze: false,
          Exhibitor: false,
        },
      },
      {
        name: 'Company name/logo on both sides of track (2 banners)',
        tiers: {
          Platinum: true,
          Gold: true,
          Silver: true,
          Bronze: false,
          Exhibitor: false,
        },
      },
      {
        name: 'Company name/logo on both sides of track (1 banners)',
        tiers: {
          Platinum: false,
          Gold: false,
          Silver: false,
          Bronze: true,
          Exhibitor: false,
        },
      },
      {
        name: 'Company name announced throughout evening',
        tiers: {
          Platinum: true,
          Gold: true,
          Silver: true,
          Bronze: true,
          Exhibitor: true,
        },
      },
      {
        name: 'Entry into the event',
        tiers: {
          Platinum: true,
          Gold: true,
          Silver: true,
          Bronze: true,
          Exhibitor: true,
        },
      },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SponsorTable() {
  return (
    <div className="bg-lightGray py-24 sm:py-32 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Sponsor Packages
          </p>
        </div>

        {/* xs to lg */}
        <div className="xl:hidden">
          <div className="mx-auto mt-12 grid grid-cols-1 gap-x-10 gap-y-10 sm:mt-16 md:grid-cols-2">
            {tiers.map((tier) => (
              <section
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'rounded-xl bg-yellow-500/50 ring-1 ring-inset ring-gray-200'
                    : 'rounded-xl bg-white ring-1 ring-inset ring-primary',
                  'p-8'
                )}
              >
                <h3
                  id={tier.id}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {tier.name}
                  <span className="pl-1">({tier.description})</span>
                </h3>
                <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
                  <span className="text-4xl font-bold">
                    {tier.priceMonthly}
                  </span>
                </p>

                <ul
                  role="list"
                  className="mt-10 space-y-4 text-sm leading-6 text-gray-900"
                >
                  {sections.map((section) => (
                    <li key={section.name}>
                      <ul role="list" className="space-y-4">
                        {section.features.map((feature) =>
                          feature.tiers[tier.name] ? (
                            <li key={feature.name} className="flex gap-x-3">
                              <CheckIcon
                                className="h-6 w-5 flex-none text-primary"
                                aria-hidden="true"
                              />
                              <span>
                                {feature.name}{' '}
                                {typeof feature.tiers[tier.name] ===
                                'string' ? (
                                  <span className="text-sm leading-6 text-gray-500">
                                    ({feature.tiers[tier.name]})
                                  </span>
                                ) : null}
                              </span>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </li>
                  ))}
                </ul>
              </section>
            ))}
          </div>
        </div>

        {/* lg+ */}
        <div className="isolate mt-20 hidden bg-white py-2 xl:block">
          <div className="relative ">
            {tiers.some((tier) => tier.mostPopular) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex ">
                <div
                  className="flex w-3/12 px-6 "
                  aria-hidden="true"
                  style={{
                    marginLeft: `${
                      (tiers.findIndex((tier) => tier.mostPopular) + 1) * 14
                    }%`,
                  }}
                >
                  <div className="w-10/12 rounded-t-xl border-x border-t border-yellow-600/10 bg-yellow-600/5" />
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-2 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {tiers.map((tier) => (
                    <th
                      key={tier.id}
                      scope="col"
                      className="px-6 pt-6 xl:px-8 xl:pt-8"
                    >
                      <div className="text-sm font-semibold leading-7 text-gray-900">
                        {tier.name}
                        <span className="pl-1">({tier.description})</span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {tiers.map((tier) => (
                    <td key={tier.id} className="px-6 pt-2 xl:px-8">
                      <div className="flex items-baseline gap-x-1 text-gray-900">
                        <span className="text-4xl font-bold">
                          {tier.priceMonthly}
                        </span>
                      </div>
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        className={classNames(
                          sectionIdx === 0 ? 'pt-8' : 'pt-16',
                          'pb-4 text-sm font-semibold leading-6 text-gray-900'
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-4 px-2 text-sm font-normal leading-6 text-gray-900"
                        >
                          {feature.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.id} className="px-6 py-4 xl:px-8">
                            {typeof feature.tiers[tier.name] === 'string' ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {feature.tiers[tier.name]}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <CheckIcon
                                    className="mx-auto h-5 w-5 text-primary"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon
                                    className="mx-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.name] === true
                                    ? 'Included'
                                    : 'Not included'}{' '}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
