import * as React from 'react';
import { LightBulbIcon } from '@heroicons/react/24/outline';

import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import SponsorPackage from '../components/sponsorPackage';
import SponsorTable from '../components/sponsorTable';
import HistoryPage from '../components/history';
import ContactSection from '../components/contactSection';
import Colophon from '../components/colophon';

function Sponsorship() {
  return (
    <Layout>
      <Seo title="Shedden Truck and Tractor Pull" />

      <div className=" bg-primary">
        <div className=" prose prose-xl mx-auto flex max-w-7xl justify-center">
          <div className=" flex flex-col gap-x-10 px-5 pt-10 pb-20 sm:px-10 lg:flex-row lg:px-5 xl:flex-row xl:gap-x-28">
            <div className="text-white lg:w-8/12">
              <h2 className="text-white">
                Shedden Truck and Tractor Pull {new Date().getFullYear()}
              </h2>
              <p>
                Thank you for your interest and allowing us the opportunity to
                tell you about the Shedden Truck & Tractor Pull. What started
                from humble beginnings in 1973, now draws in upwards of 6000
                people, making it one of the most successful tractor pulls in
                the area. The Tractor Pull focuses on rural communities in
                Southwestern Ontario but through social media reaches pullers
                and fans from all over Ontario and into the northern United
                States.
              </p>

              <p>
                The Shedden Truck & Tractor pull is a registered not for profit
                organization. Over the last 20 years, the Shedden Tractor Pull
                Committee has been able to donate nearly $300,000, focusing on
                youth organizations and community improvements.
              </p>

              <p>
                Our small group of dedicated members are determined to continue
                putting on one of the best pulling shows around. We are one of
                the first and one of the biggest events of the pulling season!
                If you are looking to be in contact with a veteran or a
                first-time fan, male or female, the Shedden Truck & Tractor Pull
                is a great opportunity for you and your company.
              </p>
            </div>
            <div className="rounded-xl bg-white p-10 text-primary lg:mt-10 lg:w-4/12">
              <LightBulbIcon className="h-24" />
              <h2>Event Highlight</h2>
              <div className="highlights flex list-none flex-col xl:gap-y-3">
                <li className="border-b border-primary pb-2 text-xl">
                  70% of attendees are male
                </li>
                <li>65% drive a truck</li>
                <li>60% have a rural background</li>
                <li>80% are from a 50km radius</li>
                <li>100% had a good time and will tell a friend.</li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactSection />
      <SponsorTable />
      <Colophon />
    </Layout>
  );
}

export default Sponsorship;
